import App from 'srs.sharedcomponents/lib/esm/App'
import { Redirect, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import {
  ApplicationInsights,
  IPageViewTelemetry,
} from '@microsoft/applicationinsights-web'
import { env } from 'srs.sharedcomponents/lib/esm/api/env'
import { store } from 'srs.sharedcomponents/lib/esm/redux/store'
import { uuidv4 } from 'srs.sharedcomponents/lib/esm/utils/helpers'
import axios, { AxiosResponse } from 'axios'
import {
  useAppDispatch,
} from "srs.sharedcomponents/lib/esm/redux/hooks";
import { SetIsHideWarrentyOnFooter } from 'srs.sharedcomponents/lib/esm/redux/slices/footerLinksSlice'
import RouterProvider from "srs.sharedcomponents/lib/esm/components/context/router/RouterProvider";
import routesConfig from "./components/config/routes";
import { useConditionalRoutes } from './hooks/useConditionalRoutes'
import coreSrsPrimaryOverride from "./i18n/coreSrsPrimaryOverride.json"
import { i18nNamespaces as namespace } from "srs.sharedcomponents/lib/esm/i18n/i18n";
import {isBrowser} from "react-device-detect";
import { useTranslation } from 'react-i18next'


interface IAppWrapper {
  appInsights: ApplicationInsights
}

const AppWrapper = ({ appInsights }: IAppWrapper) => {
  const location = useLocation()
  const { i18n } = useTranslation();

  const isPreviewing = Boolean(isBrowser && (
    location.search.includes('builder.preview=') ||
    location.search.includes('builder.frameEditing=')
  )
);
  const dispatch = useAppDispatch();
  const { conditionalRoutes } = useConditionalRoutes()
  Object.entries(coreSrsPrimaryOverride).forEach(([locale, entity]) =>{
		Object.entries(entity).forEach(([key, value]) => {
			i18n.addResource(locale, namespace.coreSrsPrimary, key, value)
		})
	})
  useEffect(() => {
    const myRequestInterceptor = axios.interceptors.request.use((request) => {
      const { method, url, headers } = request

      if (request.headers) {
        request.headers['X-Correlation-Id'] = uuidv4()
      }

      if (request) {
        appInsights.trackEvent({
          name: 'API Request Robinair',
          properties: {
            ApplicationName: 'robinair',
            RequestPath: url ?? '',
            Method: method ?? '',
            CorrelationId: headers ? headers['X-Correlation-Id'] : '',
          },
        })
      }

      return request
    })

    return () => {
      axios.interceptors.request.eject(myRequestInterceptor)
    }
  })

  useEffect(() => {
    const myResponseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const { config, status, statusText } = response
        const { method, url, headers } = config

        if (config) {
          appInsights.trackEvent({
            name: 'API Response Robinair',
            properties: {
              ApplicationName: 'robinair',
              RequestPath: url ?? '',
              Method: method ?? '',
              CorrelationId: headers ? headers['X-Correlation-Id'] : '',
              StatusCode: status,
              StatusCodeText: statusText,
            },
          })
        }

        return response
      },
      (error) => {
        // You can also log the error details in case of request failure
        appInsights.trackEvent({
          name: 'API Error',
          properties: {
            message: error.message,
            // Additional properties as needed
          },
        })

        return Promise.reject(new Error(error))
      },
    )

    return () => {
      axios.interceptors.response.eject(myResponseInterceptor)
    }
  })

  useEffect(() => {
    const pageInfo: IPageViewTelemetry = {
      name: location?.pathname,
      uri: window?.location.href,
      isLoggedIn: !!store.getState().user?.user?.token,
    }
    if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
      appInsights.trackPageView(pageInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    dispatch(SetIsHideWarrentyOnFooter(true))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const routes = {
    ...routesConfig,
    publicRoutes:conditionalRoutes
  }

  const anonymousUserRoutes = [
    "/login",
    "/PasswordRecovery",
    "/registration",
    "/contactus",
    "/cookies",
    "/warranty",
    "/opensources",
    "/getcorporateinformation",
    "/webaccessibility",
    "/mypreferences",
    "/Security/PasswordRecovery",
    "/Pages/PasswordRecovery.aspx",
    "/pagesSecurity/PasswordRecovery",
  ];

  const shouldRedirect= (!isPreviewing &&
    !store.getState().user?.user?.token &&
      !anonymousUserRoutes.find((publicRoute) => location?.pathname.includes(publicRoute))
  )
  return (
    <RouterProvider routesConfig={routes}>
      {shouldRedirect &&
        <Redirect to="/login" />
      }
      <App />
    </RouterProvider>
  )
}

export default AppWrapper

