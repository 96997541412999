import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import {
  lowercaseRegex,
  uppercaseRegex,
  digitRegex,
  specialSymbolRegex,
  IsValidRetailerCode,
} from 'srs.sharedcomponents/lib/esm/utils/constants'
import { ObjectShape, TypeOfShape } from 'yup/lib/object'
import { AnyObject } from 'yup/lib/types'

const RegistrationSchema = () => {

  const { t } = useTranslation()

  const registrationSchema: AnyObject | ObjectShape | TypeOfShape<AnyObject> = {
    error: yup.string(),
    mail: yup
      .string()
      .required(t(`CreateUserEmailValidatorErrorMsg`, { ns: namespace.coreSrsPrimary }))
      .email(t(`CreateUserInvalidEmailValidatorErrorMsg1`, { ns: namespace.coreSrsPrimary }))
      .max(60, t(`CreateUserEmailLenghtErrorMsg`, { ns: namespace.coreSrsPrimary })),
    confirmEmail: yup
      .string()
      .required(
        t(`CreateUserConfirmEmailValidatorErrorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .max(60, t(`CreateUserEmailConfirmationLenghtErrorMsg`, { ns: namespace.coreSrsPrimary }))
      .oneOf(
        [yup.ref('mail'), null],
        t(`CreateUserEmailMatchValidatorErrorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
    firstName: yup.string().required(
      t(`CreateUserFNValidatorErrorMsg`, {
        ns: namespace.coreSrsPrimary,
      }),
    ),
    middleName: yup.string(),
    lastName: yup.string().required(
      t(`CreateUserLNValidatorErrorMsg`, {
        ns: namespace.coreSrsPrimary,
      }),
    ),
    password: yup
      .string()
      .required(
        t(`CreateUserPWDValidatorErrorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .min(
        10,
        t(`PasswordRuleMinLengthLabel`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .matches(
        lowercaseRegex,
        t(`PasswordRuleLowerCaseLetterLabel`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .matches(
        uppercaseRegex,
        t(`PasswordRuleCapitalLetterLabel`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .matches(
        digitRegex,
        t(`PasswordRuleNumericLabel`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .matches(
        specialSymbolRegex,
        t(`PasswordRuleSpecialCharLabel`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
    confirmPassword: yup
      .string()
      .required(
        t(`CreateUserConfirmPWDValidatorErrorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      )
      .oneOf(
        [yup.ref('password'), null],
        t(`CreateUserPasswordsMatchValidatorErrorMsg`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
    securityQuestion: yup.string().required(
      t(`CreateUserSecurityQuestionValidatorErrorMsg`, {
        ns: namespace.coreSrsPrimary,
      }),
    ),
    securityAnswer: yup
      .string()
      .required(t(`CreateUserSecurityAnswerValidatorErrorMsg`, { ns: namespace.coreSrsPrimary })),
    retailerCode: yup
      .string()
      .required(t(`RetailerCodeValidatorErrorMsg`, { ns: namespace.coreSrsPrimary }))
      .matches(
        IsValidRetailerCode,
        t(`CreateUserDealerCodeValidatorErrorMsg`, { ns: namespace.coreSrsPrimary }),
      ),
    retailerZip: yup
    .string()
    .required(t(`RetailerZipCodeValidatorErrorMsg`, { ns: namespace.coreSrsPrimary })),
    privacyPolicy: yup
      .bool()
      .required('Required')
      .oneOf(
        [true],
        t(`valPrivacyPolicyRequired`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
    terms: yup
      .bool()
      .required('Required')
      .oneOf(
        [true],
        t(`valTermsandConditionsofUseRequired`, {
          ns: namespace.coreSrsPrimary,
        }),
      ),
    newsletter: yup.boolean(),
  }

  return registrationSchema
}

export default RegistrationSchema
